import React from "react"
import "@brainhubeu/react-carousel/lib/style.css"
import { ArrowLeft } from "styled-icons/fluentui-system-filled"
import { ArrowForwardIos } from "styled-icons/material"
import { Star } from "styled-icons/boxicons-solid"
import Image from "../components/image"

import headerInfo from "../images/header-info.jpeg"
import peopleTest1 from "../images/person-bootcamp-1.png"
import peopleTest2 from "../images/person-bootcamp-2.png"
import peopleTest3 from "../images/person-bootcamp-3.png"
import {
  BootCampCard,
  CoachesSection,
  ComunnitySection,
  ContactSection,
  ContenSection,
  HeroImage,
  InfoSection,
  ItemsSection,
  NabvarInfo,
  PaymentsSection,
  ProfetionalSections,
  SelectedOptionDot,
  SessionsSection,
  TestimonialsSection,
  TracingSection,
  VisibilitySection,
  Wrapper,
} from "../styles/pages/info"
import CarouselCouches from "../components/Info/CarouselCouches"
import ItemAccordion from "../components/Info/ItemAccordion"
import Testimonial from "../components/Info/Testimonial"
import CarouselTestimonial from "../components/Info/CarouselTestimonial"

import logo from "../images/imgAboutFour.png"
import Layout from "../components/layout"

export default function info() {
  const isBrowser = () => typeof window !== "undefined"

  const [tabActive, setTabActive] = React.useState("")

  function navigateTo(id) {
    if (isBrowser()) {
      setTimeout(() => {
        const element = document.getElementById(id)
        function navigateWithHash() {
          const position = element.getBoundingClientRect()
          window.scrollTo(position.left, position.top + window.scrollY - 180)
        }
        if (element) {
          navigateWithHash(element)
          setTabActive(id)
        }
      }, 300)
    }
  }

  const [scrollPosition, setScrollPosition] = React.useState(0)
  const handleScroll = () => {
    if (isBrowser()) {
      const position = window.pageYOffset
      setScrollPosition(position)
    }
  }

  React.useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("scroll", handleScroll, { passive: true })
    }

    return () => {
      if (isBrowser()) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const contenList = [
    // {
    //   title: "Inteligencia Artificial",
    //   description: `Nuestra Inteligencia Artificial buscará por ti vacantes que estén alineadas con tu objetivo profesional.`,
    // },
    {
      title: "Plataforma e-learning",
      description: `Accede a contenido exclusivo diseñado por expertos en Recursos Humanos con los hacks más actualizados en el mundo laboral.`,
    },
    {
      title: "Tablero de vacantes",
      description: `Te brindaremos acceso a una herramienta en donde podrás dar seguimiento a todas las vacantes a las que apliques. ¡Así nunca olvidarás nada!`,
    },
  ]

  const testimonial = [
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FIrving%20Toledo%20ok.jpg?alt=media&token=4b6689ba-df02-4ab9-afdc-2438dd2b867d",
      label: "",
      description:
        "Desde que apliqué para recibir el servicio de la LA PIEZA, han sucedido solo cosas buenas, " +
        "la ejecutiva que tuve asignada Isis, tiene un sexto sentido hiper desarrollado con el que captó exactamente " +
        "que era lo que yo estaba buscando y necesitaba en función a la etapa de mi vida en la que me encontraba. " +
        "Siempre los recomendaré porque son gente muy profesional, llenos de energía y con una gran capacidad de empatizar " +
        "con sus clientes. Estaré agradecido siempre con ustedes. ❤️",
      countryImg: "",
      names: "Irving Toledo Vazquez",
      position: "",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FAlexia.jpg?alt=media&token=31a547d3-8714-4d25-82d8-6291108c7611",
      label: "",
      description:
        "Mi experiencia en Academy fue super grata, no solo porque gracias a eso encontré un trabajo en el que me siento" +
        " super feliz, si no también porque aprendí muchísimo sobre el campo laboral, cosas y procesos que no conocía, " +
        "forme parte de una comunidad magnifica, tanto que cada vez que tengo oportunidad lo recomiendo a amigos y conocidos" +
        " que se que buscan una oportunidad como esta, LaPieza Academy no es solo un botcamp para encontrar trabajo, es amistad, " +
        "consejos, motivación y mucho aprendizaje.",
      countryImg: "",
      names: "Alexia Montoy",
      position: "",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FJulio%20Cesar.jpg?alt=media&token=25d8da4d-b128-49b5-a53f-ba4f2feb6fdc",
      label: "",
      description:
        "Pues me gustaría comenzar por comentar que fue una experiencia totalmente nueva para mi y que me ayudó mucho a buscar " +
        "y tocar puertas de empleos, y gracias a eso pude encontrar mi primer empleo. Lo que hace que haya sido una experiencia" +
        " muy importante para mí en mi vida y muy satisfactoria por la atención y ayuda recibida durante el bootcamp.",
      countryImg: "",
      names: "Julio Cesar Cantun Paez",
      position: "",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FLuis%20Manuel.jpg?alt=media&token=cc8a15f7-86a1-4fd6-929f-56519dfeddae",
      label: "",
      description:
        "¡Gracias al team de la Academy!" +
        "Gracias por ayudarme a descubrir el potencial en mi, por crear dirección a la búsqueda de mi" +
        " desarrollo profesional, y sobre todo por enseñarme LinkedIn. 🙌🏻",
      countryImg: "",
      names: "Luis Manuel Dominguez Aguilar",
      position: "",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FKaren.jpeg?alt=media&token=6022e152-6f8a-4e9b-a3e9-29f68dbd1e3b",
      label: "",
      description:
        "Lo que más me gustó del Bootcamp fue el acompañamiento y seguimiento que tiene todo el equipo de LaPieza Academy con nosotros. Me ayudaron a desenvolverme y a potenciar mi perfil.",
      countryImg: "",
      names: "Karen Michel Sixto Lázaro",
      position: "",
    },
  ]

  const couchesView = [
    // {
    //   name: "Pol Morral",
    //   position: "CEO en LaPieza. ",
    //   img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FPOL.png?alt=media&token=b1f7543a-a6db-457d-8698-030bde705e2a",
    //   linkedin: "https://www.linkedin.com/in/pol-morral-dauvergne/",
    // },
    {
      name: "Erick Ramírez",
      position: "Academy Manager & Creador de Contenido sobre Empleabilidad",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2FRecurso%205.png?alt=media&token=5904c205-3624-4de0-9c67-c8ccb2fbefad",
      linkedin: "https://www.linkedin.com/in/erickrcolunga",
    },
  ]

  const couches = [
    {
      name: "Erick Ramírez",
      position: "LaPieza Academy Manager & Career Coach.",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FERICK.png?alt=media&token=ef99b426-055b-4c97-bdff-79127e48f34d",
      linkedin: "https://www.linkedin.com/in/erickrcolunga",
    },
    {
      name: "Tulia Valdez",
      position: "Career Coach",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FTULIA.png?alt=media&token=588a01c6-b630-40a9-a613-2130197ea55c",
      linkedin: "https://www.linkedin.com/in/tuliavaldez/",
    },
    {
      name: "Alejandra Isosorbe",
      position: "Career Coach",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FALE.png?alt=media&token=2e5acaa0-c766-459e-a298-ed08cd45c8ed",
      linkedin: "https://www.linkedin.com/in/alejandra-isosorbe/",
    },
  ]

  return (
    <Layout>
      <Wrapper>
        <BootCampCard scrollY={scrollPosition}>
          <article>
            <h2>Programa de Empleabilidad</h2>
            {scrollPosition < 400 && (
              <>
                <p>
                  Diseñado para elevar tu perfil y guiarte paso a paso hacia tu
                  próximo desafío laboral.
                </p>
                <div className="person-list">
                  <img src={peopleTest1} alt="" />
                  <img src={peopleTest2} alt="" />
                  <img src={peopleTest3} alt="" />
                </div>
                <ul>
                  {/* <li>
                  <Star size={20} color="#FFC728" />{" "}
                  <p>
                    Aplicaciones automáticas y selección de vacantes acordes a
                    tu perfil
                  </p>
                </li> */}
                  <li>
                    <Star size={20} color="#FFC728" />
                    <p>
                      Creación de un CV y de un perfil atractivo en portales de
                      empleo
                    </p>
                  </li>
                  <li>
                    <Star size={20} color="#FFC728" />
                    <p>Acompañamiento personalizado con un Career Coach </p>
                  </li>
                  <li>
                    <Star size={20} color="#FFC728" />
                    <p>Acceso a contenido en video</p>
                  </li>
                  <li>
                    <Star size={20} color="#FFC728" />
                    <p>Beneficios exclusivos </p>
                  </li>
                </ul>
              </>
            )}
            <h3>
              ¡Sin costo inicial!
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Trazado_11120"
                  data-name="Trazado 11120"
                  d="M667.5,7.048c-.474,3.469-2.377,16.7-2.974,20.85A.967.967,0,0,0,665.6,29l18.128-2.259a.967.967,0,0,0,.847-.905l.712-12.479a.968.968,0,0,0-.966-1.023h-5.45a.968.968,0,0,1-.968-.968V7.18a.968.968,0,0,0-.968-.968h-8.478a.967.967,0,0,0-.959.836"
                  transform="matrix(0.995, -0.105, 0.105, 0.995, -661.522, 65.454)"
                  fill="#02c1b3"
                />
              </svg>
            </h3>
            <label>Solo pagas si consigues empleo.</label>
            <a
              href="https://form.typeform.com/to/UyF3aITf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aplica hoy
            </a>
          </article>
          <figure>
            <Image name="logo-lapieza" />
          </figure>
        </BootCampCard>
        <HeroImage>
          <img
            src={headerInfo}
            alt=""
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </HeroImage>
        <NabvarInfo>
          <ul>
            <li>
              <button
                onClick={() => {
                  setTabActive("")
                  if (isBrowser()) {
                    window.scrollTo(0, 0)
                  }
                }}
              >
                <ArrowLeft size={24} color="#100242" />
              </button>
            </li>
            <li>
              <button onClick={() => navigateTo("contenido")}>Contenido</button>
              <SelectedOptionDot active={tabActive === "contenido"} />
            </li>
            <li>
              <button onClick={() => navigateTo("seguimiento")}>
                Seguimiento
              </button>
              <SelectedOptionDot active={tabActive === "seguimiento"} />
            </li>
            <li>
              <button onClick={() => navigateTo("visibilidad")}>
                Visibilidad
              </button>
              <SelectedOptionDot active={tabActive === "visibilidad"} />
            </li>
            <li>
              <button onClick={() => navigateTo("testimonials")}>
                Testimonios
              </button>
              <SelectedOptionDot active={tabActive === "testimonials"} />
            </li>
            <li>
              <button onClick={() => navigateTo("plan")}>Plan de Pagos</button>
              <SelectedOptionDot active={tabActive === "plan"} />
            </li>
          </ul>
        </NabvarInfo>
        <InfoSection>
          <article>
            <h1>Conoce el programa</h1>
            <div className="article-content">
              <div className="info-figures">
                <figure />
                <figure />
              </div>
              <div className="article-text">
                <h5>Descripción</h5>
                <p>
                  Optimizaremos tu perfil profesional y te haremos destacar
                  entre los cientos de postulantes en el mercado laboral.
                </p>
                {/* <p>
                ¡Además utilizaremos Inteligencia Artificial para impulsar tu
                perfil!
              </p> */}
                {/*<p>*/}
                {/*  Aprenderás cuáles son las bases para conseguir empleo en la*/}
                {/*  realidad actual y cómo ser unx candidatx atractivx para las*/}
                {/*  empresas.*/}
                {/*</p>*/}
              </div>
            </div>
          </article>
        </InfoSection>
        <ContenSection id="contenido">
          <label htmlFor="title">Contenido del programa</label>
          <h2>¡Capacítate con especialistas en reclutamiento!</h2>
          <article className="article-one">
            <div className="margin-article" />
            <div>
              <h5>Conoce nuestro Programa de Empleabilidad:</h5>
              <p>
                Programa virtual donde descubrirás los mejores tips de
                reclutamiento y claves de empleabilidad que transformarán tus
                postulaciones y te guiarán a tu próximo trabajo.
              </p>
            </div>
          </article>
          <article className="article-two">
            <img src="" alt="" />
            <h3>Siguiente paso, ¡profundiza!</h3>
            <p>
              En compañía de tu Career Coach podrás adentrarte aún más en el
              contenido del programa con atención personalizada enfocada en la
              fase del proceso en la que te encuentres.
            </p>
          </article>
        </ContenSection>
        <ItemsSection>
          <article>
            <ul>
              <li>CV</li>
              <li>Marca personal</li>
              <li>LinkedIn</li>
            </ul>
            <ul>
              <li>Estrategia de búsqueda y postulación de empleo</li>
              <li>Entrevistas laborales</li>
            </ul>
            <ul>
              <li>Negociación ante una oferta</li>
              <li>Y más</li>
            </ul>
          </article>
        </ItemsSection>
        <ProfetionalSections>
          <h3>Tendrás acceso a contenido e-learning impartido por…</h3>
          <div className="profetional-list">
            {couchesView?.map((couche, idx) => (
              <article key={idx.toString()}>
                {couche?.img ? (
                  <img
                    src={couche?.img}
                    alt="logo"
                    style={{
                      maxWidth: "6em",
                      height: " 6em",
                    }}
                  />
                ) : (
                  <Image name="person-test" />
                )}
                <div>
                  <h5>{couche?.name}</h5>
                  <p>{couche?.position}</p>
                </div>
              </article>
            ))}
          </div>
          <span className="profetional-link">
            <p>Conoce más </p>
            <a href="https://form.typeform.com/to/UyF3aITf">
              aplicando ahora
              <span>
                <ArrowForwardIos size={20} />
              </span>
            </a>
          </span>
        </ProfetionalSections>
        <TracingSection id="seguimiento">
          <label htmlFor="title-2">Seguimiento</label>
          <h2> ¡Tu perfil destacará más! </h2>
          <article>
            <Image name="tracing" />
            <div>
              <h5>Atrae a las empresas</h5>
              <p>
                Tu Career Coach hará que tu perfil profesional sea más atractivo
                para los reclutadores.
              </p>
            </div>
          </article>
        </TracingSection>
        <SessionsSection>
          <h2>Mejoraremos tu…</h2>
          <article>
            <ul>
              <li>CV</li>
              <li>Linkedin</li>
              <li>OCC</li>
            </ul>
            <ul>
              <li>LaPieza</li>
              <li>Otros portales más</li>
            </ul>
          </article>
          <div className="sessions-list">
            <h4>¿Cómo te apoyaremos?</h4>
            <table>
              <tr>
                <th>
                  Al ingresar a nuestro Programa de Empleabilidad contarás con:
                </th>
              </tr>
              <tr>
                <td>
                  <h5>Coaching personalizado</h5>
                  <p>
                    ¡Empezamos con todo! Durante este periodo, tu Career Coach
                    te apoyará puliendo y posicionando tu perfil para despegar
                    con tus postulaciones.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Seguimiento vía WhatsApp</h5>
                  <p>
                    A tan solo un click de distancia tendrás toda la asesoría y
                    respuestas que necesites de tu Career Coach.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Sesiones semanales de comunidad</h5>
                  <p>
                    Participa en nuestras reuniones de comunidad para hacer
                    networking y seguir avanzando hacia tu meta.
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </SessionsSection>
        <CoachesSection>
          <h3>Tus Coaches</h3>
          <CarouselCouches>
            {couches.map((item, idx) => {
              return (
                <article key={idx}>
                  <figure>
                    <img src={item.img || logo} alt="Coache | LaPieza" />
                  </figure>
                  <p>{item.name}</p>
                  <p>{item.position}</p>
                  {/* <p>3+ años de experiencia</p> */}
                  {item.linkedin && (
                    <a
                      href={item.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: "2em",
                        paddingBottom: "8px",
                        paddingTop: "8px",
                      }}
                    >
                      Visita su LinkedIn
                    </a>
                  )}
                </article>
              )
            })}
          </CarouselCouches>
        </CoachesSection>
        <VisibilitySection id="visibilidad">
          <label htmlFor="title-3">Beneficios que tenemos para ti</label>
          <h2>Aumenta tus posibilidades</h2>
          <ItemAccordion contenList={contenList} />
        </VisibilitySection>
        <ComunnitySection id="comunidad">
          <label htmlFor="title-4">Comunidad</label>
          <h2>¡Juntos es mejor!</h2>
          <div className="comunnity-content">
            <figure>
              <Image name="comunnity" />
            </figure>
            <article>
              <h5>Comunidad</h5>
              <p>
                Formarás parte de nuestra comunidad de piezeros, quienes al
                igual que tú, se encuentran buscando su empleo ideal.
              </p>
              <ul>
                <li>Apoyo Peer to Peer</li>
                <li>Eventos privados</li>
                <li>Conferencias con invitados especiales </li>
                <li>Networking con piezerxs graduadxs</li>
                <li>¡Y más! </li>
              </ul>
            </article>
          </div>
        </ComunnitySection>
        <TestimonialsSection id="testimonials">
          <div className="bg-testimonials" />
          <article>
            <h4>Testimonios</h4>
            <CarouselTestimonial>
              {testimonial.map((item, idx) => {
                const key = `key-testimonial-${idx}`
                return <Testimonial key={key} data={item} />
              })}
            </CarouselTestimonial>
            {/* Aqui va la navegación */}
          </article>
        </TestimonialsSection>
        <PaymentsSection id="plan">
          <div>
            <label htmlFor="title-5">Plan de pagos</label>
            <h2>¡Solo pagas si consigues empleo!</h2>
            <article>
              <div>
                <p>
                  Tu inversión será el equivalente a un mes de tu nuevo sueldo
                  bruto si consigues empleo ¡Descuida, el pago puede ser
                  diferido a 3 meses!
                </p>
                <p className="description">
                  Garantía: Puedes darte de baja sin ningún costo antes de que
                  termine tu primera sesión con tu Career Coach.
                </p>
              </div>
              <figure>
                <Image name="person-3-test" />
              </figure>
            </article>
            <a
              href="https://form.typeform.com/to/UyF3aITf"
              target="_blank"
              rel="noopener noreferrer"
            >
              ¡Aplica ahora!
            </a>
          </div>
        </PaymentsSection>
        <ContactSection>
          <article>
            <h2>¿Buscas una opción diferente?</h2>
            <p>
              Consigue una asesoría personalizada en empleabilidad con un
              experto de Academy.
            </p>
            <p>¡Agenda tu asesoría y mejora tu carrera hoy mismo!</p>
            <div>
              <a
                href="http://bit.ly/academybylapieza"
                target="_blank"
                rel="noopener noreferrer"
              >
                ¡Agenda tu asesoría ahora!
              </a>
              {/* <Link to="/contact">¡Agenda tu asesoría ahora!</Link> */}
              {/* <Link to="/plans">Conoce el plan para ti</Link> */}
            </div>
          </article>
        </ContactSection>
      </Wrapper>
    </Layout>
  )
}
