import React from "react"
import Carousel, { slidesToShowPlugin, Dots } from "@brainhubeu/react-carousel"
import {
  ArrowIosBackOutline,
  ArrowIosForwardOutline,
} from "styled-icons/evaicons-outline"
import { NavigationButtons } from "../../styles/pages/info"

export default function CarouselCouches({ children }) {
  const [dotValue, setDotValue] = React.useState(0)

  const isBrowser = () => typeof window !== "undefined"

  const onChangeDot = newValue => {
    setDotValue(newValue)
  }

  return (
    <div className="coaches-list">
      {isBrowser() && (
        <>
          <Carousel
            onChange={onChangeDot}
            value={dotValue}
            plugins={[
              // "infinite",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3,
                },
              },
            ]}
            breakpoints={{
              768: {
                plugins: [
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 2,
                    },
                  },
                ],
              },
              520: {
                plugins: [
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 1,
                    },
                  },
                ],
              },
            }}
          >
            {children}
          </Carousel>

          <NavigationButtons center>
            <button
              onClick={() => {
                setDotValue(prevState => {
                  if (prevState === 0) {
                    return prevState
                  }
                  return prevState - 1
                })
              }}
              disabled={dotValue === 0}
            >
              <ArrowIosBackOutline color="#bdbdbd" size={32} />
            </button>
            <Dots
              value={dotValue}
              onChange={onChangeDot}
              number={children?.length >= 5 ? 5 : children?.length || 0}
              thumbnails={(children || []).map((i, idx) => {
                const key = `carousel-item-key${idx}`
                return <div key={key} className="dot-custom" />
              })}
            />
            <button
              onClick={() => {
                setDotValue(prevState => {
                  if (prevState + 1 === children.length) {
                    return prevState
                  }
                  return prevState + 1
                })
              }}
              disabled={dotValue + 1 === children.length}
            >
              <ArrowIosForwardOutline color="#bdbdbd" size={32} />
            </button>
          </NavigationButtons>
        </>
      )}
    </div>
  )
}
