import React from "react"
import { TestimonialItem } from "../../styles/pages/info"
import Image from "../../components/image"

export default function Testimonial(props) {
  const { data } = props
  return (
    <TestimonialItem>
      <figure className="person-image">
        <p>{data.label || "Contratadx"}</p>
          {
              data?.img ? <img src={data?.img} alt="imagen"/> : <Image name="person-test-2" />
          }
      </figure>
      <div>
        <p className="description">{data.description}</p>
        <span className="person-name">
          <figure className="country-image">
            <Image name="mex" />
          </figure>
          <label htmlFor="subtitle">{data.names}</label>
        </span>
        <p className="person-position">{data.position}</p>
      </div>
    </TestimonialItem>
  )
}
