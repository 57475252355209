import React from "react"
import { ArticleItemAccordion } from "../../styles/pages/info"
import { Plus, Minus } from "styled-icons/boxicons-regular"
import Image from "../image"

export default function ItemAccordion(props) {
  const { contenList = [] } = props

  const [state, setState] = React.useState(0)

  const onImageGeneral = () => {
    console.log(state)
    switch (state) {
      case 1:
        return <Image name="visibility2" />
      case 2:
        return <Image name="visibilty3" />
      case 0:
      default:
        return <Image name="visibility" />
    }
  }

  return (
    <div className="content-info">
      <div className="article-list">
        {contenList.map((item, idx) => {
          const key = `key-article-${idx}`
          return (
            <ArticleItemAccordion open={state === idx} idx={idx} key={key}>
              <div className="article-header">
                <h5>{item.title}</h5>
                <button
                  onClick={() => {
                    setState(prevState => {
                      if (prevState === idx) {
                        return false
                      }
                      return idx
                    })
                  }}
                >
                  {state === idx ? (
                    <Minus size={24} color="#02C1B3" />
                  ) : (
                    <Plus size={24} color="#02C1B3" />
                  )}
                </button>
              </div>
              <div className="article-body">
                <p>{item.description}</p>
              </div>
            </ArticleItemAccordion>
          )
        })}
      </div>
      <figure>{onImageGeneral()}</figure>
    </div>
  )
}
